<template>
  <kinesis-container event="move">
    <video-background
        :src="require('@/assets/video_background_duathlon.mp4')"
        object-fit="cover"
        style="height: 100vh;"
        overlay="linear-gradient(rgba(30,30,30,1) 0%, rgba(0,0,0,0) 70%)"
        class="d-flex align-center justify-center"
    >
      <v-container
          class="fill-height d-flex justify-center"
      >
        <kinesis-element axis="y" :strength="0.6" type="scale">
          <v-img :src=logo
                 :width="$vuetify.breakpoint.mdAndUp ? 400 : 140"
                 class="mx-auto"
          ></v-img>
        </kinesis-element>
      </v-container>
    </video-background>
  </kinesis-container>
</template>

<script>
import i18n from '@//plugins/i18n'
import{KinesisElement, KinesisContainer} from 'vue-kinesis'
import VideoBackground from 'vue-responsive-video-background-player'

export default {
  components:{
    KinesisContainer,
    KinesisElement,
    VideoBackground
  },
  props:[
    'src'
  ],
  data: () => ({
    title: '#Jemeinsam',
    logo: require('@/assets/img/KTT_LOGO_210513_color_white.png')
  }),
  computed: {
    currentLang () {
      return i18n.locale
    }
  }
}
</script>
